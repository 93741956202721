import UserIdentification from 'UserIdentification';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Loader';
import Routes from 'components/Routes';
import Toast from 'components/Toast';
import GlobalContextProvider from 'context/GlobalContext/GlobalContext';
import { BrowserRouter } from 'react-router-dom';
import { Userpilot } from 'userpilot';

// Initialize Userpilot
Userpilot.initialize('NX-a83f2f17');

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <GlobalContextProvider>
          <BrowserRouter>
            <Routes />
            <UserIdentification />
            <Toast />
            <Loader />
          </BrowserRouter>
        </GlobalContextProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
