import appConfig from 'appConfig';

const apiConfig = {
  login: `${appConfig.BASE_URL_AUTH}api/v1/auth/login`,
  otpVerification: `${appConfig.BASE_URL_AUTH_DONE}api/v1/auth/verify-otp`,
  register: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,
  forgotPassword: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/forgotPassword`,
  resetPassword: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/resetPassword`,

  getUserDetails: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,
  updateUserDetails: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,
  uploadUserProfileImage: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/upload-image`,

  vendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  updateVendorStatus: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  removeVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  communityVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/top-vendors`,
  communityMembersList: (userId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/communityVendors/${userId}`,
  communityUsersCount: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/community-users-list`,
  communityMemberDetails: (memberId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/community-vendors/${memberId}`,
  removedVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/removed-vendors`,
  addVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/addVendor/`,
  addPopularVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  vendorSearch: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendors`,
  vendorSearchAdd: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  vendorDetails: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendors/${vendorId}`,
  vendorIntentList: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendor-intent/${vendorId}`,
  vendorUsersCount: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendor-users-list/${vendorId}`,
  popularVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/popular-vendors`,
  supportFormEmail: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/support-email`,

  // Rating API
  postCompanyRating: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/company-rating`,
  postTelepersonRating: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/teleperson-rating`,
  getCompanyRating: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/company-rating/${vendorId}`,
  getTelepersonRating: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/teleperson-rating/${vendorId}`,

  // MX API
  mxEmail: (email = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/mx-user?email=${email}`,
  mxCreateUser: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/create-mx-user`,
  mxWidget: (guid = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/mx-widget-url?guid=${guid}`,
  mxAddUser: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/add-mx-user-details`,

  // twilio API
  twilioToken: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/token`,
  twilioLog: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/twiliocallinglogs`,

  RegistrationVerification: (token = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/verify-email/${token}`,

  // Transaction API
  userTransaction: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/userTransction`,
};

export default apiConfig;
